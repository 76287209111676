import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { ContentContainer, SectionHeader, Text } from "../../W3libComponents";
import { Table } from "../../organisms/table/Table";

const tableHeader = [{ text: "Nome" }, { text: "E-mail" }];

export const UserList = ({ context }) => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const { setLoading } = context;

  useEffect(() => {
    document.title = `TuimBox Admin - Usuários`;
    setLoading(true);
    api
      .get(`/v3/admin/user`)
      .then((response) => {
        setLoading(false);
        setUsers(response.data.users);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const handleNewUser = useCallback(() => history.push(`/user/new`), [history]);

  return (
    <>
      <SectionHeader
        title={`Usuários`}
        newButtonAction={() => handleNewUser()}
        newButton={true}
      />

      <ContentContainer>
        <Table
          size="small"
          header={tableHeader}
          rows={users?.map((user, index) => {
            return {
              to: `/user/${user._id}`,
              data: [
                <Text>{user?.name || "-"}</Text>,
                <Text>{user?.email || "-"}</Text>,
              ],
            };
          })}
        />
      </ContentContainer>
    </>
  );
};
