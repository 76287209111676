import React, { useState } from "react";
import { FaCommentDots, FaRegCommentDots } from "react-icons/fa";
import { Link } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import { useFetch } from "../../../hooks/useFetch";
import { usePagination } from "../../../hooks/usePagination";
import {
  ContentContainer,
  Pagination,
  SearchBar,
  SectionHeader,
} from "../../W3libComponents";
import { Box, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { NoDataFound } from "../../organisms/no-data-found/no-data-found";

export const EvaluationsReport = () => {
  const [filters, setFilters] = useState({
    filterName: "createdAt",
    filterOrder: "asc",
  });
  const { filterName, filterOrder } = filters;
  const {
    limit,
    page,
    skip,
    nextPage,
    prevPage,
    changeLimit,
    searchTerms,
    setSearchTerms,
  } = usePagination({ localStorageName: "tuimbox-list-clients-pagination" });

  const { data } = useFetch({
    url: `/v3/admin/evaluations?page=${page}&skip=${skip}&limit=${limit}&filterName=${filters.filterName}&filterOrder=${filters.filterOrder}&searchTerms=${searchTerms}`,
  });

  const getTopicEvaluation = (topicEvaluation = null) => {
    if (topicEvaluation) {
      let greenStars = topicEvaluation;
      let grayStars = 5 - greenStars;

      return (
        <>
          {[...Array(greenStars)].map((e, i) => (
            <Box
              key={`green${i}`}
              style={{
                ...styles.star,
                backgroundImage: `url('/icons/green_star.png')`,
              }}
            />
          ))}
          {[...Array(grayStars)].map((e, i) => (
            <Box
              key={`gray${i}`}
              style={{
                ...styles.star,
                backgroundImage: `url('/icons/gray_star.png')`,
              }}
            />
          ))}
        </>
      );
    }
    return <></>;
  };

  const getAverageRating = () => {
    if (data?.items) {
      let sumOfAllEvaluations = 0;
      (data?.items).map((e) => {
        sumOfAllEvaluations +=
          (e.evaluation?.cleaning +
            e.evaluation?.conservation +
            e.evaluation?.costBenefit +
            e.evaluation?.quality) /
          4;
      });

      return `${(sumOfAllEvaluations / data?.items.length).toFixed(2)} / 5`;
    }
    return `-`;
  };

  return (
    <>
      <SectionHeader
        title={`Avaliações`}
        subtitle={data?.items?.length > 0 ? `Média: ${getAverageRating()}` : ""}
      />

      <ContentContainer>
        <Box sx={{ display: "flex", gap: 3 }}>
          <SearchBar
            placeholder="Nome do produto ou usuário"
            searchTerms={searchTerms}
            onSearch={setSearchTerms}
          />
          <Pagination
            localStorageName="tuimbox-list-evaluations-pagination"
            dataLength={data?.evaluationCount[0]?.count || 0}
            nextPage={nextPage}
            prevPage={prevPage}
            page={page}
            limit={limit}
            changeLimit={changeLimit}
          />
        </Box>
        {data?.items?.length > 0 ? (
          <Box
            style={{
              border: "1px solid #e4e4e4",
              backgroundColor: "#fff",
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            <Box style={{ width: "100%", display: "flex" }}>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 160 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "createdAt" ? "underline" : "none",
                  }}
                >
                  Data
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "createdAt"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "createdAt",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, flex: 1 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "userName" ? "underline" : "none",
                  }}
                >
                  Usuário
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "userName"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "userName",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, flex: 1 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "products" ? "underline" : "none",
                  }}
                >
                  Produto
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "products"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "products",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 110 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "cleaning" ? "underline" : "none",
                  }}
                >
                  Limpeza
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "cleaning"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "evaluation.cleaning",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 110 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "conservation" ? "underline" : "none",
                  }}
                >
                  Conservação
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "conservation"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "evaluation.conservation",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 110 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "evaluation.costBenefit"
                        ? "underline"
                        : "none",
                  }}
                >
                  Custo/Benef.
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "costBenefit"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "evaluation.costBenefit",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 110 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "quality" ? "underline" : "none",
                  }}
                >
                  Qualidade
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "quality"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "evaluation.quality",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 65 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "observation" ? "underline" : "none",
                  }}
                >
                  Obs.
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "observation"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "evaluation.observation",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column" }}>
              {(data?.items).map((contract) => (
                <Link
                  key={`evaluations_report_${contract._id}`}
                  to={`/relatorios/avaliacoes/${contract._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={styles.tableRow}>
                    <Box style={{ ...styles.row, width: 160 }}>
                      <Text style={{ ...styles.cellText, textAlign: "center" }}>
                        {formatTimestamp({
                          timestamp: contract?.evaluation?.createdAt,
                          showTime: false,
                        })}
                      </Text>
                    </Box>
                    <Box
                      style={{
                        ...styles.row,
                        flex: 1,
                        justifyContent: "flex-start",
                      }}
                    >
                      <Text style={{ ...styles.cellText }}>
                        {contract?.user?.name || "Não informado"}
                      </Text>
                    </Box>
                    <Box
                      style={{
                        ...styles.row,
                        flex: 1,
                        justifyContent: "flex-start",
                      }}
                    >
                      <Text style={{ ...styles.cellText }}>
                        {contract?.products?.name || "Não informado"}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 110 }}>
                      {getTopicEvaluation(contract?.evaluation?.cleaning)}
                    </Box>
                    <Box style={{ ...styles.row, width: 110 }}>
                      {getTopicEvaluation(contract?.evaluation?.conservation)}
                    </Box>
                    <Box style={{ ...styles.row, width: 110 }}>
                      {getTopicEvaluation(contract?.evaluation?.costBenefit)}
                    </Box>
                    <Box style={{ ...styles.row, width: 110 }}>
                      {getTopicEvaluation(contract?.evaluation?.quality)}
                    </Box>
                    <Box style={{ ...styles.row, width: 65 }}>
                      <Text style={{ ...styles.cellText, textAlign: "center" }}>
                        {Boolean(contract?.evaluation?.observation) ? (
                          <FaCommentDots size={16} color={Colors.darkGreen} />
                        ) : (
                          <FaRegCommentDots size={16} color="#ccc" />
                        )}
                      </Text>
                    </Box>
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        ) : (
          <NoDataFound />
        )}
      </ContentContainer>
    </>
  );
};

const styles = {
  cellText: {
    fontSize: 14,
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableHeadRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableHeadText: {
    fontSize: "14px",
    color: "#333",
    fontFamily: "UbuntuBold",
    marginLeft: 7,
  },
  tableRow: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 45,
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "#e4e4e4",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 5,
    paddingBottom: 5,
  },
  star: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 12,
    height: 12,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: "content-box",
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
};
