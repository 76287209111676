import { Box } from "@mui/material";
import { Text } from "../../atoms";

export const NoDataFound = () => {
  return (
    <Box sx={styles.wrapper}>
      <Text center>Nenhum resultado encontrado.</Text>
    </Box>
  );
};

const styles = {
  wrapper: {
    width: "100%",
    height: 240,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
