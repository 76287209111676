import { useContext, useEffect } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  AppContract,
  Box,
  ClientEdit,
  ClientsList,
  ContractDetail,
  ContractList,
  Dashboard,
  DoorEdit,
  EditHomeKey,
  EditUnit,
  EditVoucher,
  EvaluationDetails,
  EvaluationsReport,
  FeedbackDetails,
  Feedbacks,
  ForgotPassword,
  Header,
  LeftMenu,
  ListDoors,
  ListHomeKey,
  ListRentalPeriods,
  ListUnits,
  ListVouchers,
  LockerEdit,
  LockerList,
  Login,
  MacroReport,
  ProductEdit,
  ProductList,
  ResetPassword,
  SuggestionsDetails,
  SuggestionsReport,
  TaskList,
  TuimReport,
  UserEdit,
  UserList,
} from "../../W3libComponents";
import { Colors } from "./Colors";
import ScrollToTop from "./ScrollToTop";

const Application = () => {
  const {
    user,
    doLogout,
    loadingCurrentUser,
    requestUserInformation,
    checkPermission,
    statistics,
  } = useContext(AuthContext);
  const { setLoading, loading, alert } = useContext(AppContext);

  const userIsAdmin = user?.permission?.includes("Administrador");
  const userIsManager = user?.permission?.includes("BuildingManager");

  const context = {
    setLoading,
    loading,
    alert,
    checkPermission,
    user,
    doLogout,
    statistics,
  };
  useEffect(() => {
    requestUserInformation();
  }, []);

  const redirect = new URLSearchParams(window.location.search).get("redirect");

  return (
    <>
      {!user && loadingCurrentUser && <div>Loading...</div>}
      {!user && !loadingCurrentUser && (
        <Router>
          <Switch>
            <Route path="/" exact>
              <Login context={context} />
            </Route>
            <Route path="/forgot-password" exact>
              <ForgotPassword context={context} />
            </Route>
            <Route path="/reset-password/:token">
              <ResetPassword context={context} />
            </Route>
            <Route path="/*">
              <Redirect
                to={`/?redirect=${encodeURIComponent(
                  window.location.pathname
                )}`}
              />
            </Route>
          </Switch>
        </Router>
      )}
      {user && (
        <>
          <div id="mainContainer" style={styles.container}>
            <Box sx={styles.bodyContainer}>
              <Router>
                <ScrollToTop />
                <LeftMenu />
                <div style={styles.mainContentContainer}>
                  <Header context={context} />
                  <Box sx={styles.contentContainer}>
                    <Switch>
                      <>
                        {userIsAdmin && (
                          <>
                            <Route path="/" exact>
                              <Redirect
                                from="/"
                                to={redirect || "/dashboard"}
                              />
                            </Route>
                            <Route path="/dashboard" exact>
                              <Dashboard context={context} />
                            </Route>
                            <Route path="/tasks" exact>
                              <TaskList />
                            </Route>
                            <Route path="/users" exact>
                              <UserList context={context} />
                            </Route>
                            {/* <Route path="/user/new" exact>
                                       <UserNew context={context} />
                                    </Route> */}
                            <Route path="/user/:id" exact>
                              <UserEdit context={context} />
                            </Route>
                            <Route path="/products" exact>
                              <ProductList context={context} />
                            </Route>
                            <Route path="/products/:id" exact>
                              <ProductEdit context={context} />
                            </Route>
                            <Route path="/lockers" exact>
                              <LockerList context={context} />
                            </Route>
                            <Route path="/lockers/:id" exact>
                              <LockerEdit context={context} />
                            </Route>
                            <Route path="/contracts" exact>
                              <ContractList context={context} />
                            </Route>
                            <Route path="/contracts/:id" exact>
                              <ContractDetail context={context} />
                            </Route>
                            <Route path="/relatorios/feedbacks" exact>
                              <Feedbacks context={context} />
                            </Route>
                            <Route path="/relatorios/feedbacks/:id" exact>
                              <FeedbackDetails context={context} />
                            </Route>
                            <Route path="/relatorios/avaliacoes" exact>
                              <EvaluationsReport context={context} />
                            </Route>
                            <Route path="/relatorios/avaliacoes/:id" exact>
                              <EvaluationDetails context={context} />
                            </Route>
                            <Route path="/relatorios/sugestoes" exact>
                              <SuggestionsReport context={context} />
                            </Route>
                            <Route path="/relatorios/sugestoes/:id" exact>
                              <SuggestionsDetails context={context} />
                            </Route>
                            <Route path="/relatorios/macro" exact>
                              <MacroReport context={context} />
                            </Route>
                            <Route path="/relatorios/tuim" exact>
                              <TuimReport context={context} />
                            </Route>
                            <Route path="/doors" exact>
                              <ListDoors context={context} />
                            </Route>
                            <Route path="/doors/:id" exact>
                              <DoorEdit context={context} />
                            </Route>
                            <Route path="/clients" exact>
                              <ClientsList context={context} />
                            </Route>
                            <Route path="/clients/:id" exact>
                              <ClientEdit context={context} />
                            </Route>
                            <Route path="/vouchers" exact>
                              <ListVouchers context={context} />
                            </Route>
                            {/* <Route path="/vouchers/new" exact>
                                       <EditVoucher context={context} />
                                    </Route> */}
                            <Route path="/vouchers/:id" exact>
                              <EditVoucher context={context} />
                            </Route>
                            <Route path="/homekeys" exact>
                              <ListHomeKey context={context} />
                            </Route>
                            {/* <Route path="/homekeys/new" exact>
                                       <EditHomeKey context={context} />
                                    </Route> */}
                            <Route path="/homekeys/:id" exact>
                              <EditHomeKey context={context} />
                            </Route>
                            <Route path="/building-unit" exact>
                              <ListUnits context={context} />
                            </Route>
                            <Route path="/building-unit/:id" exact>
                              <EditUnit context={context} />
                            </Route>
                            <Route path="/app/contract" exact>
                              <AppContract context={context} />
                            </Route>
                            <Route path="/rental-periods" exact>
                              <ListRentalPeriods context={context} />
                            </Route>
                          </>
                        )}
                        {userIsManager && (
                          <>
                            <Route path="/" exact>
                              <Redirect from="/" to="/dashboard" />
                            </Route>
                            <Route path="/dashboard" exact>
                              <Dashboard context={context} />
                            </Route>
                          </>
                        )}
                      </>
                    </Switch>
                  </Box>
                </div>
              </Router>
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default Application;

const styles = {
  container: {
    minHeight: "100vh",
  },
  bodyContainer: {
    display: "flex",
    height: "100%",
    minHeight: "100vh",
    width: "100%",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: `25px`,
    padding: `0px 40px`,
    paddingBottom: `60px`,
  },
  mainContentContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    flex: 1,
    gap: `25px`,
    backgroundColor: Colors.background,
    overflowY: "hidden",
  },
};
