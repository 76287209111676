import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import { useFetch } from "../../../hooks/useFetch";
import { usePagination } from "../../../hooks/usePagination";
import {
  ContentContainer,
  Pagination,
  SearchBar,
  SectionHeader,
} from "../../W3libComponents";
import { Box, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { StatusLabel } from "../../organisms/layout/StatusLabel";
import { NoDataFound } from "../../organisms/no-data-found/no-data-found";

export const Feedbacks = () => {
  const [filters, setFilters] = useState({
    filterName: "id",
    filterOrder: "asc",
  });
  const { filterName, filterOrder } = filters;
  const statusOptions = [
    {
      value: "aberto",
      display: "Aberto",
      color: Colors.red,
    },
    {
      value: "em andamento",
      display: "Em andamento",
      color: Colors.yellow,
    },
    {
      value: "pendente",
      display: "Pendente",
      color: Colors.yellow,
    },
    {
      value: "resolvido",
      display: "Resolvido",
      color: Colors.green,
    },
  ];
  const {
    limit,
    page,
    skip,
    nextPage,
    prevPage,
    changeLimit,
    searchTerms,
    setSearchTerms,
  } = usePagination({ localStorageName: "tuimbox-list-feedbacks-pagination" });

  const { data } = useFetch({
    url: `/v3/admin/feedbacks?page=${page}&skip=${skip}&limit=${limit}&filterName=${filters.filterName}&filterOrder=${filters.filterOrder}&searchTerms=${searchTerms}`,
  });
  console.log({ searchTerms });
  return (
    <>
      <SectionHeader
        title={`Feedbacks`}
        subtitle={
          data?.items?.length > 0
            ? `Ativos: ${(data?.items?.filter((i) => i.active)).length}`
            : ""
        }
      />

      <ContentContainer>
        <Box sx={{ display: "flex", gap: 3 }}>
          <SearchBar
            placeholder="Nome do cliente ou motivo"
            onSearch={setSearchTerms}
            searchTerms={searchTerms}
          />
          <Pagination
            localStorageName="tuimbox-list-feedbacks-pagination"
            dataLength={data?.feedbackCount?.[0]?.count || 0}
            nextPage={nextPage}
            prevPage={prevPage}
            page={page}
            limit={limit}
            changeLimit={changeLimit}
          />
        </Box>
        {data?.items?.length > 0 ? (
          <Box
            style={{
              border: "1px solid #e4e4e4",
              backgroundColor: "#fff",
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            <Box style={{ width: "100%", display: "flex" }}>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 70 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "idCode" ? "underline" : "none",
                  }}
                >
                  ID
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "idCode"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "idCode",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 150 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "status" ? "underline" : "none",
                  }}
                >
                  Status
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "status"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "status",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 150 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "createdAt" ? "underline" : "none",
                  }}
                >
                  Data
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "createdAt"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "createdAt",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 250 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "subject" ? "underline" : "none",
                  }}
                >
                  Motivo
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "subject"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "subject",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, flex: 1 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "id_user" ? "underline" : "none",
                  }}
                >
                  Nome
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "id_user"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "id_user",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, height: 50, width: 200 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filterName === "user_type" ? "underline" : "none",
                  }}
                >
                  Tipo
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filterName === "user_type"
                        ? filterOrder === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() =>
                    setFilters({
                      filterName: "user_type",
                      filterOrder:
                        filters?.filterOrder === "asc" ? "desc" : "asc",
                    })
                  }
                />
              </Box>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column" }}>
              {(data?.items).map((item) => (
                <Link
                  key={`feedbacks_report_${item._id}`}
                  to={`/relatorios/feedbacks/${item._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={styles.tableRow}>
                    <Box style={{ ...styles.row, width: 70 }}>
                      <Text style={{ ...styles.text, textAlign: "center" }}>
                        {item?.idCode}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 150 }}>
                      <StatusLabel
                        background="light"
                        currentStatus={item?.status}
                        dropDownOptions={statusOptions}
                      />
                    </Box>
                    <Box style={{ ...styles.row, width: 150 }}>
                      <Text style={{ ...styles.text, textAlign: "center" }}>
                        {formatTimestamp({
                          timestamp: item?.createdAt,
                          showTime: false,
                        })}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 250 }}>
                      <Text style={{ ...styles.text, textAlign: "center" }}>
                        {item?.subject}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, flex: 1 }}>
                      <Text style={{ ...styles.text, textAlign: "center" }}>
                        {item?.id_user?.name || item?.name || "-"}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 200 }}>
                      <Text style={{ ...styles.text, textAlign: "center" }}>
                        {item?.user_type}
                      </Text>
                    </Box>
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        ) : (
          <NoDataFound />
        )}
      </ContentContainer>
    </>
  );
};

const styles = {
  mainContainer: {
    width: "100%",
    position: "relative",
  },
  tableHeadRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableHeadText: {
    fontSize: "14px",
    color: "#333",
    textAlign: "center",
    fontFamily: "UbuntuBold",
    marginLeft: 7,
  },
  tableRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "#e4e4e4",
    height: 45,
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  sectionTitle: {
    fontWeight: 500,
    color: "#333",
  },
  tableRowHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  textTitle: {
    fontWeight: 500,
    fontSize: "18px",
    color: "#333",
  },
  text: {
    fontSize: "14px",
    color: "#333",
  },
  headerContainer: {
    display: "flex",
    marginBottom: 22,
  },
  feedbackStatus: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "red",
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: "content-box",
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
};
