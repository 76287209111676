import { useEffect } from "react";
import { Link } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import { useFetch } from "../../../hooks/useFetch";
import { usePagination } from "../../../hooks/usePagination";
import api from "../../../services/api";
import {
  ContentContainer,
  Pagination,
  SearchBar,
  SectionHeader,
} from "../../W3libComponents";
import { Box, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { NoDataFound } from "../../organisms/no-data-found/no-data-found";

export const ClientsList = ({ context }) => {
  const { setLoading } = context;

  const {
    limit,
    page,
    skip,
    nextPage,
    prevPage,
    changeLimit,
    searchTerms,
    setSearchTerms,
    orderBy,
    setOrderBy,
    direction,
    setDirection,
  } = usePagination({ localStorageName: "tuimbox-list-clients-pagination" });

  const { data, isLoading } = useFetch({
    url: `/v3/admin/clients?page=${page}&skip=${skip}&limit=${limit}&orderBy=${orderBy}&direction=${direction}&searchTerms=${searchTerms}`,
  });

  const updateClient = async ({ clientId, data }) => {
    setLoading(true);
    await api
      .patch(`/v3/admin/clients/${clientId}`, data)
      .then()
      .catch((error) => console.log(error))
      .finally(async () => {
        setLoading(false);
        window.location.reload();
      });
  };

  const handleClientActivation = (event, clientId, active) => {
    event.preventDefault();
    updateClient({
      clientId,
      data: {
        active: !active,
      },
    });
  };

  useEffect(() => {
    document.title = `TuimBox Admin - Clientes`;
  }, []);

  if (isLoading) return <p>Carregando...</p>;

  return (
    <>
      <SectionHeader
        title={`Clientes`}
        subtitle={
          data?.items?.length > 0
            ? `Ativos: ${(data?.items?.filter((i) => i.active)).length}`
            : ""
        }
      />
      <ContentContainer>
        <Box sx={{ display: "flex", gap: 3 }}>
          <SearchBar
            placeholder="Marina, marcos@tuim.com.br..."
            searchTerms={searchTerms}
            onSearch={setSearchTerms}
          />
          <Pagination
            localStorageName="tuimbox-list-clients-pagination"
            dataLength={data?.clientsCount?.[0]?.count || 0}
            nextPage={nextPage}
            prevPage={prevPage}
            page={page}
            limit={limit}
            changeLimit={changeLimit}
          />
        </Box>
        {data?.items?.length > 0 ? (
          <Box
            style={{
              border: "1px solid #e4e4e4",
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
          >
            <Box style={{ width: "100%", display: "flex" }}>
              <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "name" ? "underline" : "none",
                  }}
                >
                  Nome
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "name"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("name");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "email" ? "underline" : "none",
                  }}
                >
                  E-mail
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "email"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("email");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 200 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "createdAt" ? "underline" : "none",
                  }}
                >
                  Data do Cadastro
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "createdAt"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("createdAt");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 100 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "active" ? "underline" : "none",
                  }}
                >
                  Ativo
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "active"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("active");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
            </Box>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              {(data?.items).map((client) => (
                <Link
                  key={`clients_list_${client._id}`}
                  to={`/clients/${client._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={styles.tableRow}>
                    <Box style={{ ...styles.row, flex: 1 }}>
                      <Text style={styles.cellText}>{client?.name}</Text>
                    </Box>
                    <Box style={{ ...styles.row, flex: 1 }}>
                      <Text style={styles.cellText}>
                        {client?.email || "Não informado"}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 200 }}>
                      <Text style={styles.cellText}>
                        {formatTimestamp({
                          timestamp: client?.createdAt,
                          showSeconds: false,
                        })}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 100 }}>
                      <Box
                        sx={{
                          ...styles.checkIcon,
                          zIndex: 99,
                          backgroundImage: client?.active
                            ? `url('/icons/green_check_icon.png')`
                            : `url('/icons/gray_check_icon.png')`,
                          "&:hover": { opacity: 0.8 },
                        }}
                        onClick={(event) =>
                          handleClientActivation(
                            event,
                            client._id,
                            client.active
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        ) : (
          <NoDataFound />
        )}
      </ContentContainer>
    </>
  );
};

const styles = {
  tableHeadRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  tableHeadText: {
    fontSize: "14px",
    color: "#333",
    textAlign: "center",
    fontFamily: "UbuntuBold",
    marginLeft: 7,
  },
  arrowUpIcon: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: "content-box",
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
  tableRow: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "#e4e4e4",
    "&:hover": {
      backgroundColor: Colors.background,
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  cellText: {
    fontSize: 14,
    textAlign: "center",
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  dropDownIcon: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 12,
    height: 12,
    marginLeft: 2,
  },
  paginationDropDownOptionsContainer: {
    position: "absolute",
    width: 94,
    left: 0,
    top: 32,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eee",
    zIndex: 9999999,
  },
  paginationDropDownOptions: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 0.5,
    paddingBottom: 0.5,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  paginationButtonContainer: {
    position: "relative",
    height: 25,
    width: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  paginationButton: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    transform: "rotate(-90deg)",
  },
  paginationDropDownContainer: {
    position: "relative",
    width: 60,
    height: 30,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#ddd",
    display: "flex",
    alignItems: "center",
    paddingLeft: 2,
    paddingRight: 2,
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
};
