import { useContext, useEffect, useReducer, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { SecondaryButton } from "../../organisms/button/Buttons";
import { Colors } from "../../organisms/layout/Colors";
import { Box, ContentContainer, Text, TextField } from "../../W3libComponents";
import { Link } from "react-router-dom";

export const Login = ({ context }) => {
  const { setLoading, alert } = context;
  const { doLogin } = useContext(AuthContext);

  const [user, setUser] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { email: "", password: "" }
  );
  const [windowWidth, setWindowWidth] = useState(0);
  const smallWidthDevice = windowWidth < 1000;

  const handleChange = ({ currentTarget }) => {
    setUser({ [currentTarget.name]: currentTarget.value });
  };

  const handleLogin = () => {
    if (user.email === "" || user.password === "") {
      alert.error("Parece que você não preencheu um dos campos.");
      return;
    }
    doLogin(user, handleError);
  };

  const handleError = (err) => {
    alert.error(err?.data?.message);
    setLoading(false);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    document.title = `TuimBox Admin - Login`;
    return () => window.removeEventListener("resize", () => {});
  }, []);

  return (
    <Box style={styles.container}>
      <div style={styles.firstBackground} />
      <div style={styles.secondBackground} />
      <div style={styles.thirdBackground} />
      <div style={styles.fourthBackground} />
      <Box
        sx={{
          display: "flex",
          ...(smallWidthDevice
            ? { height: "90%", width: "90%" }
            : { height: "70%", width: "70%" }),
        }}
      >
        <ContentContainer
          row
          fullWidth
          style={{ padding: 0, zIndex: 99999 }}
          gap={0}
        >
          {smallWidthDevice ? (
            <></>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                flex: 1,
                backgroundColor: "#fff",
                gap: 1,
              }}
            >
              <img
                src="/images/login_img.jpg"
                alt="TuimBox Illustration"
                style={{ height: "60%", width: "auto", objectFit: "contain" }}
              />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              gap: 3,
              backgroundColor: "#fff",
              backgroundColor: Colors.darkGreen,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 6,
                alignItems: "center",
                width: smallWidthDevice ? "80%" : "60%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: "UbuntuBold",
                    color: "#fff",
                  }}
                >{`Bem-vindo(a)`}</Text>
                <Text style={{ fontSize: 14, color: "#ffffff77" }}>
                  Faça login para acessar o painel.
                </Text>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  width: "100%",
                }}
              >
                <TextField
                  placeholder="email@tuimbox.com.br"
                  value={user.email}
                  onChange={handleChange}
                  name="email"
                  margin="none"
                  fullWidth
                  InputProps={{
                    style: {
                      fontSize: 14,
                      backgroundColor: "#ffffff22",
                      color: "#ffffffbb",
                      outline: "none",
                    },
                  }}
                />
                <TextField
                  placeholder="******"
                  value={user.password}
                  onChange={handleChange}
                  name="password"
                  type="password"
                  margin="none"
                  fullWidth
                  InputProps={{
                    style: {
                      fontSize: 14,
                      backgroundColor: "#ffffff22",
                      color: "#ffffffbb",
                      outline: "none",
                    },
                  }}
                />
              </Box>
              <SecondaryButton
                style={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  backgroundColor: "#f1f1f1",
                }}
                text="Entrar"
                onClick={handleLogin}
              />

              <Link
                style={{ color: "#ffffffbb", fontSize: 14 }}
                to="/forgot-password"
              >
                Esqueci minha senha
              </Link>
            </Box>
          </Box>
        </ContentContainer>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    flex: 1,
    backgroundColor: Colors.background,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
  },
  firstBackground: {
    position: "absolute",
    top: -370,
    right: -60,
    width: 300,
    height: 600,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
    backgroundColor: Colors.darkGreen + "11",
  },
  secondBackground: {
    position: "absolute",
    top: 70,
    left: 400,
    width: 200,
    height: 200,
    borderRadius: 8,
    transform: "rotate(-10deg)",
    backgroundColor: Colors.darkGreen + "21",
  },
  thirdBackground: {
    position: "absolute",
    bottom: 60,
    right: 250,
    width: 200,
    height: 200,
    borderRadius: "50%",
    backgroundColor: Colors.darkGreen + "21",
  },
  fourthBackground: {
    position: "absolute",
    bottom: -370,
    left: -60,
    width: 300,
    height: 600,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
    backgroundColor: Colors.darkGreen + "11",
  },
  loginContainer: {
    flexDirection: "column",
    padding: 40,
    display: "flex",
    width: 340,
    borderRadius: 10,
    zIndex: 99999,
  },
};
