import { useEffect, useState } from "react";

export const usePagination = ({
  localStorageName,
  customFilter1 = "customFilter1",
}) => {
  const urlParams = new URLSearchParams(window.location.search);

  const [page, setPage] = useState(() => {
    return parseInt(urlParams.get("page"), 10) || 1;
  });
  const [searchTerms, setSearchTerms] = useState(
    () => new URL(window.location).searchParams.get("searchTerms") || ""
  );
  const [orderBy, setOrderBy] = useState(
    () => new URL(window.location).searchParams.get("orderBy") || "createdAt"
  );
  const [direction, setDirection] = useState(
    () => new URL(window.location).searchParams.get("direction") || "desc"
  );
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(() => {
    const limitValue =
      Number(JSON.parse(window.localStorage.getItem(localStorageName))) ||
      parseInt(urlParams.get("limit"), 10) ||
      10;

    return limitValue;
  });

  const [customFilter1Value, setCustomFilter1] = useState(customFilter1);

  const updatePaginationParams = () => {
    const url = new URL(window.location);
    url.searchParams.set("page", page);
    url.searchParams.set("limit", limit);
    url.searchParams.set("orderBy", orderBy);
    url.searchParams.set("direction", direction);
    window.history.replaceState({}, "", url);
  };

  const updateSearchTermsParams = () => {
    setPage(1);
    const url = new URL(window.location);
    url.searchParams.set("page", 1);

    if (Boolean(searchTerms)) {
      url.searchParams.set("searchTerms", searchTerms);
    } else {
      url.searchParams.delete("searchTerms");
    }

    window.history.replaceState({}, "", url);
  };

  const goToPage = (newPage) => setPage(newPage);
  const nextPage = () => goToPage(page + 1);
  const prevPage = () => goToPage(page > 1 ? page - 1 : 1);

  const changeLimit = (newLimit) => setLimit(newLimit);

  const handleUpdateCustomFilter1 = (value) => {
    setPage(1);
    setCustomFilter1(value);
  };

  useEffect(() => {
    updatePaginationParams();
    setSkip((page - 1) * limit);
  }, [page, limit]);

  useEffect(() => {
    updatePaginationParams();
  }, [orderBy, direction]);

  useEffect(() => {
    updateSearchTermsParams();
  }, [searchTerms]);

  return {
    page,
    nextPage,
    prevPage,
    changeLimit,
    limit,
    skip,
    searchTerms,
    setSearchTerms,
    orderBy,
    setOrderBy,
    direction,
    setDirection,
    customFilter1: customFilter1Value,
    setCustomFilter1: handleUpdateCustomFilter1,
  };
};
