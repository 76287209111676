import { useEffect, useState } from "react";
import { MdDone, MdOutlineCopyAll } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import {
  Box,
  ContentContainer,
  SectionHeader,
  Text,
  TextField,
} from "../../W3libComponents";
import { CustomButton, Spacer } from "../../atoms";
import { B2CLabel } from "../../atoms/B2CLabel";
import { Colors } from "../../organisms/layout/Colors";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";

export const LockerEdit = ({ context }) => {
  const { id } = useParams();
  const newLocker = id === "new" ? true : false;
  const [showDetails, setShowDetails] = useState({ active: false, id: null });
  const [lockerWasUpdated, setLockerWasUpdated] = useState(false);
  const [B2CLocker, setB2CLocker] = useState(false);
  const [locker, setLocker] = useState({
    name: "",
    active: false,
    description: "",
    manufacturer: "",
    address: {
      bairro: "",
      cep: "",
      cidade: "",
      complemento: "",
      estado: "",
      numero: "",
      rua: "",
    },
    idLoopBox: "",
  });

  const { setLoading, alert, user } = context;
  const history = useHistory();

  useEffect(() => {
    if (newLocker) {
      document.title = `TuimBox Admin - Novo Locker`;
      return;
    }

    loadLocker();
  }, []);

  useEffect(() => {
    setLockerWasUpdated(true);
  }, [locker]);

  const loadLocker = async () => {
    setLoading(true);
    api
      .get(`/v3/admin/locker/${id}`)
      .then((response) => {
        document.title = `TuimBox Admin - Locker ${response?.data?.locker?.name}`;
        setLocker(response.data.locker);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (
      name === "name" ||
      name === "description" ||
      name === "manufacturer" ||
      name === "idLoopBox" ||
      name === "active" ||
      name === "boxCode" ||
      name === "freeRents" ||
      name === "freeHours" ||
      name === "serial" ||
      name === "freeDailyRents"
    ) {
      setLocker({
        ...locker,
        [name]: value,
      });
    } else {
      setLocker({
        ...locker,
        address: {
          ...locker.address,
          [name]: value,
        },
      });
    }
  };

  const handleSave = async () => {
    if (newLocker) {
      handleCreate();
      return;
    }

    setLoading(true);
    await api
      .patch(`/v3/admin/locker/${id}`, { locker })
      .then((response) => {
        alert.success("Locker atualizado com sucesso!");
      })
      .catch((error) => {
        alert.error(
          error.response?.data?.message || "Por favor, tente novamente"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreate = async () => {
    setLoading(true);
    await api
      .post(`/v3/admin/locker`, { locker })
      .then((response) => {
        setLoading(false);
        alert.success("Locker criado com sucesso!");
        if (!response?.data?.data?._id) return;

        window.open(`/lockers/${response.data.data._id}`, "_self");
      })
      .catch((err) => {
        console.log(err);
        alert.error("Desculpe. Tivemos um problema na criação do locker.");
        setLoading(false);
      });
  };

  const handleDeleteLocker = async () => {
    setLoading(true);
    !newLocker &&
      (await api
        .delete(`/v3/admin/locker/${id}`)
        .then((response) => {
          alert.success("Locker excluído com sucesso!");
          history.push(`/lockers`);
          setLoading(false);
        })
        .catch((err) => {
          alert.error("Desculpe. Não foi possível excluir o locker.");
          setLoading(false);
        }));
  };

  const handleChangeActivation = () => {
    setLocker({
      ...locker,
      active: !locker.active,
    });
  };

  const HeaderSubtitle = () => (
    <Box sx={{ display: "flex", gap: 1 }}>
      {B2CLocker ? <B2CLabel /> : <></>}
    </Box>
  );

  return (
    <>
      <SectionHeader
        title={`Locker ${locker?.name}`}
        subtitle={<HeaderSubtitle />}
        saveButton={lockerWasUpdated}
        saveButtonAction={handleSave}
        deleteButton={!newLocker}
        deleteButtonAction={handleDeleteLocker}
      />

      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", gap: 3 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 3, flex: 1 }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "row", gap: 3, flex: 1 }}
              >
                <ContentContainer fullWidth>
                  <SmallSectionTitle title="Dados do Locker" />
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        variant="standard"
                        label="Nome"
                        refs="name"
                        name="name"
                        margin="dense"
                        value={locker?.name}
                        autoFocus
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                      />
                      <Spacer horizontal />
                      <TextField
                        variant="standard"
                        label="ID LoopBox"
                        refs="idLoopBox"
                        name="idLoopBox"
                        margin="dense"
                        value={locker?.idLoopBox}
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                        style={{ maxWidth: 90 }}
                      />
                    </Box>
                    <TextField
                      variant="standard"
                      label="Descrição"
                      refs="description"
                      name="description"
                      margin="dense"
                      value={locker?.description}
                      onChange={handleChange}
                      InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { color: "#888" } }}
                    />
                    <TextField
                      variant="standard"
                      label="Serial"
                      refs="serial"
                      name="serial"
                      margin="dense"
                      value={locker?.serial || ""}
                      onChange={handleChange}
                      InputProps={{ shrink: true, style: { fontSize: 14 } }}
                      InputLabelProps={{
                        style: { color: "#888" },
                      }}
                    />
                    <TextField
                      variant="standard"
                      label="Fabricante"
                      refs="manufacturer"
                      name="manufacturer"
                      margin="dense"
                      value={locker?.manufacturer}
                      onChange={handleChange}
                      InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { color: "#888" } }}
                    />
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <TextField
                        variant="standard"
                        label="Locações Mensais"
                        refs="freeRents"
                        name="freeRents"
                        margin="dense"
                        value={locker?.freeRents ?? 0}
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                      />
                      <TextField
                        variant="standard"
                        label="Locações Diárias"
                        refs="freeDailyRents"
                        name="freeDailyRents"
                        margin="dense"
                        value={locker?.freeDailyRents ?? 0}
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                      />
                      <TextField
                        variant="standard"
                        label="Duração (hrs)"
                        refs="freeHours"
                        name="freeHours"
                        margin="dense"
                        value={locker?.freeHours ?? 0}
                        onChange={handleChange}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: "#888" } }}
                      />
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", gap: 20 }}>
                    <Box
                      sx={styles.activeContainer}
                      onClick={() => handleChangeActivation()}
                    >
                      <Box
                        sx={{
                          ...styles.checkIcon,
                          backgroundImage: locker.active
                            ? `url('/icons/green_check_icon.png')`
                            : `url('/icons/gray_check_icon.png')`,
                        }}
                      />
                      <Spacer horizontal />
                      <Box sx={styles.activeDoorTextContainer}>
                        <Text style={styles.regularText}>
                          {locker.active ? `Locker Ativo` : `Locker Inativo`}
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      sx={styles.activeContainer}
                      onClick={() =>
                        window.open(
                          `http://www.tuimbox.com.br/locker/${id}`,
                          "_blank"
                        )
                      }
                    >
                      <Box
                        sx={{
                          ...styles.checkIcon,
                          backgroundImage: `url('/icons/gray_contract.png')`,
                        }}
                      />
                      <Spacer horizontal />
                      <Box sx={styles.activeDoorTextContainer}>
                        <Text style={styles.regularText}>Acessar Catálogo</Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      gap: 8,
                      width: "100%",
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="standard"
                      label="QR code link"
                      refs="qrCodeUrl"
                      name="qrCodeUrl"
                      margin="dense"
                      value={locker?.qrCodeUrl || ""}
                      onChange={handleChange}
                      InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { color: "#888" } }}
                      disabled
                    />
                    <CustomButton
                      startIcon={MdOutlineCopyAll}
                      endIcon={MdDone}
                      startText="Copiar link"
                      endText="Copiado!"
                      onClick={() =>
                        navigator.clipboard.writeText(locker.qrCodeUrl)
                      }
                    />
                  </Box>
                </ContentContainer>

                {locker?.manufacturer === "Chavi" && !newLocker && (
                  <ContentContainer
                    style={{
                      minWidth: 280,
                      display: "flex",
                    }}
                  >
                    <SmallSectionTitle title="Histórico da Rede" />
                    {locker?.networkHistory?.length > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          flexDirection: "column",
                          maxHeight: 488,
                          overflow: "auto",
                        }}
                      >
                        {locker?.networkHistory?.map((item, index) => {
                          const showLogDetails =
                            showDetails?.id === item?._id &&
                            showDetails?.active;
                          const event = item.updatedFields[0];
                          const online = event.networkStatus === "online";
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                gap: 2,
                                flexDirection: "row",
                                alignItems: "start",
                                height: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                  height: "100%",
                                  paddingBottom: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    minWidth: 12,
                                    minHeight: 12,
                                    borderRadius: "50%",
                                    marginTop: 0.5,
                                    backgroundColor: online
                                      ? Colors.lemonGreen
                                      : Colors.red,
                                  }}
                                />
                                <Box
                                  sx={{
                                    borderLeft: "1px dashed #ccc",
                                    height: "100%",
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  flexDirection: "column",
                                  position: "relative",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Text
                                    style={{
                                      whiteSpace: "wrap",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.description}
                                  </Text>
                                  <Text small style={{ color: "darkgray" }}>
                                    {formatTimestamp({
                                      timestamp: item?.createdAt,
                                      showSeconds: true,
                                    })}
                                  </Text>
                                </Box>
                                {!online && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 1,
                                      flexDirection: "column",
                                      position: "relative",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        alignItems: "center",
                                        "&:hover": {
                                          opacity: 0.8,
                                          cursor: "pointer",
                                        },
                                      }}
                                      onClick={() =>
                                        setShowDetails({
                                          active: !showDetails?.active,
                                          id: item?._id,
                                        })
                                      }
                                    >
                                      <Text small>Detalhes</Text>
                                      <Box
                                        sx={{
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          backgroundImage: `url('/icons/gray_arrow_down.png')`,
                                          transform: showLogDetails
                                            ? "rotate(0)"
                                            : "rotate(-90deg)",
                                          transition: ".3s",
                                          width: 14,
                                          height: 14,
                                        }}
                                      />
                                    </Box>
                                    {showLogDetails && (
                                      <Box
                                        sx={{
                                          position: "absolute",
                                          top: 20,
                                          zIndex: 999,
                                        }}
                                      >
                                        <Box
                                          key={index}
                                          sx={{
                                            display: "flex",
                                            gap: 1,
                                            flexDirection: "column",
                                            backgroundColor:
                                              Colors.lightBackground,
                                            padding: "10px 12px",
                                            borderRadius: 2,
                                            minWidth: 100,
                                          }}
                                        >
                                          <Box sx={{ display: "flex", gap: 1 }}>
                                            <Text bold small>
                                              Status:
                                            </Text>
                                            <Text small>
                                              {event?.networkStatus}
                                            </Text>
                                          </Box>
                                          <Box sx={{ display: "flex", gap: 1 }}>
                                            <Text bold small>
                                              Erro:
                                            </Text>
                                            <Text small>
                                              {event?.error || "-"}
                                            </Text>
                                          </Box>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          flex: 1,
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text style={{ color: "#999" }}>
                          Nenhum evento registrado
                        </Text>
                      </Box>
                    )}
                  </ContentContainer>
                )}
              </Box>

              <ContentContainer fullWidth gap={0}>
                <SmallSectionTitle title="Endereço" />
                <Spacer size="2" />
                <TextField
                  variant="standard"
                  label="Rua"
                  margin="dense"
                  name="rua"
                  fullWidth
                  value={locker.address?.rua}
                  onChange={handleChange}
                  InputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { color: "#888" } }}
                />
                <Box sx={{ display: "flex" }}>
                  <TextField
                    variant="standard"
                    label="Nº"
                    margin="dense"
                    name="numero"
                    value={locker.address?.numero}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                    style={{ maxWidth: 100 }}
                  />
                  <Spacer horizontal size="1" />
                  <TextField
                    variant="standard"
                    label="Complemento"
                    margin="dense"
                    name="complemento"
                    value={locker.address?.complemento}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    variant="standard"
                    label="Cep"
                    margin="dense"
                    name="cep"
                    value={locker?.address?.cep}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                    style={{ maxWidth: 100 }}
                  />
                  <Spacer horizontal size="1" />
                  <TextField
                    variant="standard"
                    label="Bairro"
                    margin="dense"
                    name="bairro"
                    value={locker.address?.bairro}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    variant="standard"
                    label="Estado"
                    margin="dense"
                    name="estado"
                    value={locker?.address?.estado}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                    style={{ maxWidth: 160 }}
                  />
                  <Spacer horizontal />
                  <TextField
                    variant="standard"
                    label="Cidade"
                    margin="dense"
                    name="cidade"
                    value={locker.address?.cidade}
                    onChange={handleChange}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { color: "#888" } }}
                  />
                </Box>
              </ContentContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  regularText: {
    fontSize: 14,
  },
  boldText: {
    fontSize: 14,
    fontWeight: 600,
  },
  expressNumberText: {
    fontSize: 24,
    fontWeight: 600,
  },
  activeContainer: {
    flex: 1,
    height: 30,
    backgroundColor: Colors.lightBackground,
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    padding: 1,
    "&:hover": {
      backgroundColor: Colors.hover,
      cursor: "pointer",
    },
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 22,
    height: 22,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  activeDoorTextContainer: {
    flex: 1,
    marginRight: 1,
    display: "flex",
    justifyContent: "center",
  },
  productCardMainContainer: {
    width: 600,
    height: "100%",
    overflowX: "auto",
    display: "flex",
    boxSizing: "border-box",
  },
  productCardContainer: {
    position: "relative",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#ddd",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: 1,
    borderRadius: 3,
    margin: 0.5,
    width: 137,
    overflow: "hidden",
    "&:hover": {
      cursor: "pointer",
      borderWidth: 1,
      borderColor: "#09404766",
    },
  },
  productCardContent: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundOrigin: "content-box",
    width: 80,
    height: 110,
  },
  designationSizeLabel: {
    position: "absolute",
    top: 12,
    left: 0,
    padding: `2px 8px`,
    backgroundColor: "#f0f0f0",
    borderRadius: `0px 8px 8px 0px`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
};
