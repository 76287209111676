import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDelete } from "../../../hooks/useDelete";
import { useFetch } from "../../../hooks/useFetch";
import { useSave } from "../../../hooks/useSave";
import { calcPeriodDuration } from "../../../utils";
import {
  ContentContainer,
  Text,
  TextField,
  TuimboxButton,
} from "../../W3libComponents";
import { Colors } from "../../organisms/layout/Colors";
import { DropDown } from "../../organisms/layout/DropDown";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";

const combinedPeriods = ({ existingPeriods, periodsInUse }) => {
  const periodsInUseHash = {};

  periodsInUse.sort((a, b) =>
    a.periodId.duration > b.periodId.duration ? 1 : -1
  );

  periodsInUse.forEach((item) => (periodsInUseHash[item.periodId._id] = true));

  const periods = existingPeriods.filter((item) => !periodsInUseHash[item._id]);
  const combinedPeriods = periodsInUse.concat(periods);

  return combinedPeriods;
};

const periodFields = [
  { name: "first", label: "Inicial" },
  { name: "additional", label: "Adicional" },
  { name: "discount", label: "Desconto" },
  { name: "minPrice", label: "Preço mínimo" },
];

const SelectedPeriodDetails = ({
  selectedPeriod,
  resetSelectedPeriod,
  lockerId,
  productId,
  refreshPeriodsInUse,
}) => {
  const [selectedPeriodData, setSelectedPeriodData] = useState({});
  const { saveData } = useSave({
    url: "/v3/product-locker-period",
    method: selectedPeriodData.inUse ? "patch" : "post",
  });
  const { deleteData } = useDelete({
    url: `/v3/product-locker-period/${selectedPeriodData._id}`,
  });

  useEffect(() => {
    setSelectedPeriodData(selectedPeriod);
  }, [selectedPeriod]);

  const handleChange = ({ target }) => {
    setSelectedPeriodData((prev) => ({
      ...prev,
      [target.name]: Number(target.value),
    }));
  };

  const handleSave = async () => {
    // const method = selectedPeriodData.inUse ? "patch" : "post";
    const data = selectedPeriodData.inUse
      ? selectedPeriodData
      : {
          ...selectedPeriodData,
          periodId: selectedPeriodData._id,
          lockerId,
          productId,
        };

    await saveData({ data });
    await refreshPeriodsInUse();
    resetSelectedPeriod();
  };

  const handleDelete = async () => {
    if (window.confirm("Desativar este período para este produto?")) {
      await deleteData();
      await refreshPeriodsInUse();
      resetSelectedPeriod();
    }
  };

  const saveButtonText = selectedPeriod.inUse ? "Atualizar" : "Ativar";
  const periodName = selectedPeriodData.inUse
    ? selectedPeriodData.periodId.name
    : selectedPeriodData.name;
  const periodDuration = selectedPeriodData.inUse
    ? selectedPeriodData.periodId.duration
    : selectedPeriodData.duration;

  if (!selectedPeriodData._id) return <></>;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        padding: `20px 28px`,
        flex: 1,
      }}
    >
      <Text bold title>
        {periodName}
      </Text>
      <Text style={{ color: "#888" }}>
        Duração de {calcPeriodDuration(periodDuration)}
      </Text>
      {periodFields.map((item) => (
        <Box key={item.name} style={{ display: "flex", alignItems: "center" }}>
          <Text style={{ width: 112, flex: 1 }}>{item.label}</Text>
          <TextField
            name={item.name}
            variant="outlined"
            type="number"
            size="small"
            value={selectedPeriodData[item.name] || 0}
            onChange={handleChange}
            style={{ width: 80 }}
            InputProps={{ shrink: true, style: { fontSize: 14 } }}
          />
        </Box>
      ))}
      <Box
        style={{
          display: "flex",
          gap: 4,
          alignItems: "flex-end",
          flex: 1,
        }}
      >
        <TuimboxButton text={saveButtonText} onClick={handleSave} />
        {selectedPeriod.inUse && (
          <TuimboxButton
            variant="destructive"
            text="Desativar"
            onClick={handleDelete}
          />
        )}
      </Box>
    </Box>
  );
};

const Disabled = () => (
  <Box
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#eeeeeecc",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Text>Nenhum locker selecionado</Text>
  </Box>
);

const PeriodPanel = (props) => {
  const { allPeriods, selectedLocker, productId, refreshPeriodsInUse } = props;
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const tabsRef = useRef(null);

  const resetSelectedPeriod = () => setSelectedPeriod({});
  const handleSelectPeriod = (periodData) => {
    // toggling
    if (selectedPeriod._id === periodData._id) return resetSelectedPeriod();
    setSelectedPeriod(periodData);
  };

  useEffect(() => {
    setSelectedPeriod({});
  }, [selectedLocker]);

  useEffect(() => {
    if (tabsRef.current) {
      tabsRef.current.scroll({ top: 0, behavior: "smooth" });
    }
  }, [allPeriods, selectedLocker]);

  return (
    <>
      <Box style={styles.periodPanelRoot}>
        <Box style={styles.periodPanelTabs} ref={tabsRef}>
          {allPeriods.map((period) => {
            const inUse = Boolean(period?.periodId);
            const selected = selectedPeriod._id === period._id;
            return (
              <Box
                key={period._id}
                onClick={() => handleSelectPeriod({ ...period, inUse })}
                disabled
                sx={{
                  ...styles.periodPanelSingleTab,
                  backgroundColor: inUse ? Colors.darkGreen : "#e4e4e4",
                  color: inUse ? "#fff" : "#777",
                  textDecoration: selected ? "underline" : "initial",
                }}
              >
                <Text style={{ color: "inherit" }}>
                  {inUse ? period.periodId.name : period.name}
                </Text>
                {selected && (
                  <Box
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: -14,
                      margin: "auto",
                      width: 20,
                      height: 20,
                      backgroundColor: Colors.background,
                      transform: "rotate(45deg)",
                      borderRadius: 2,
                    }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
        {selectedPeriod?._id ? (
          <SelectedPeriodDetails
            selectedPeriod={selectedPeriod}
            resetSelectedPeriod={resetSelectedPeriod}
            lockerId={selectedLocker}
            productId={productId}
            refreshPeriodsInUse={refreshPeriodsInUse}
          />
        ) : (
          <></>
        )}
        {!selectedLocker && <Disabled />}
      </Box>
    </>
  );
};

export const ProductPeriodsManager = (props) => {
  const { productId } = props;

  const [selectedLocker, setSelectedLocker] = useState({});
  const [allPeriods, setAllPeriods] = useState([]);

  const periodsInUseUrl =
    productId && selectedLocker._id
      ? `/v3/product-locker-period?productId=${productId}&lockerId=${selectedLocker._id}`
      : "";

  const {
    data: { lockers },
  } = useFetch({
    url: "/v3/admin/lockers?select=name,description",
  });

  const { data: existingPeriods } = useFetch({ url: `/v3/rental-periods` });
  const { data: periodsInUse, refreshData: refreshPeriodsInUse } = useFetch({
    url: periodsInUseUrl,
  });

  useEffect(() => {
    setAllPeriods(combinedPeriods({ existingPeriods, periodsInUse }));
  }, [periodsInUse, existingPeriods]);

  return (
    <ContentContainer style={{ width: 400 }}>
      <SmallSectionTitle title={`Períodos e valores`} />
      <DropDown
        options={lockers}
        currentValue={selectedLocker}
        onClick={setSelectedLocker}
        placeholder="Selecione um locker"
      />
      <PeriodPanel
        allPeriods={allPeriods}
        selectedLocker={selectedLocker?._id}
        productId={productId}
        refreshPeriodsInUse={refreshPeriodsInUse}
      />
    </ContentContainer>
  );
};

const styles = {
  periodPanelRoot: {
    display: "flex",
    backgroundColor: Colors.background,
    position: "relative",
    overflow: "hidden",
    borderRadius: 8,
    maxHeight: 380,
  },
  periodPanelTabs: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    overflow: "auto",
    scrollbarWidth: "thin",
  },
  periodPanelSingleTab: {
    padding: 3,
    minHeight: 20,
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
    "&:hover": {
      opacity: 0.9,
    },
  },
};
