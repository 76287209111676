import { Link, useHistory } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import { useFetch } from "../../../hooks/useFetch";
import { usePagination } from "../../../hooks/usePagination";
import api from "../../../services/api";
import {
  ContentContainer,
  Pagination,
  SearchBar,
  SectionHeader,
} from "../../W3libComponents";
import { Box, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { NoDataFound } from "../../organisms/no-data-found/no-data-found";

export const LockerList = ({ context }) => {
  const history = useHistory();
  const { setLoading, alert } = context;

  const {
    limit,
    page,
    skip,
    nextPage,
    prevPage,
    changeLimit,
    searchTerms,
    setSearchTerms,
    orderBy,
    setOrderBy,
    direction,
    setDirection,
  } = usePagination({ localStorageName: "tuimbox-list-lockers-pagination" });

  const { data } = useFetch({
    url: `/v3/admin/lockers?page=${page}&skip=${skip}&limit=${limit}&orderBy=${orderBy}&direction=${direction}&searchTerms=${searchTerms}`,
  });

  const updateLocker = async ({ lockerId, data }) => {
    setLoading(true);
    api
      .patch(`/v3/admin/locker/${lockerId}`, { locker: data })
      .then(() => alert.success("Locker atualizado com sucesso!"))
      .catch((err) => {
        console.log(err);
        alert.error("Desculpe. Não foi possível atualizar o locker.");
      })
      .finally(() => {
        setLoading(false);
        window.location.reload();
      });
  };

  const handleLockerActivation = (event, lockerId, active) => {
    event.preventDefault();
    updateLocker({
      lockerId,
      data: {
        active: !active,
      },
    });
  };

  const handleNewLocker = () => history.push("/lockers/new");

  return (
    <>
      <SectionHeader
        title={`Lockers`}
        subtitle={
          data?.items?.length > 0
            ? `Ativos: ${(data?.items?.filter((i) => i.active)).length}`
            : ""
        }
        newButtonAction={handleNewLocker}
        newButton={true}
      />

      <ContentContainer>
        <Box sx={{ display: "flex", gap: 3 }}>
          <SearchBar
            placeholder="Nome ou descrição do locker"
            searchTerms={searchTerms}
            onSearch={setSearchTerms}
          />
          <Pagination
            localStorageName="tuimbox-list-lockers-pagination"
            dataLength={data?.lockerCount?.[0]?.count || 0}
            nextPage={nextPage}
            prevPage={prevPage}
            page={page}
            limit={limit}
            changeLimit={changeLimit}
          />
        </Box>
        {data?.items?.length > 0 ? (
          <Box
            style={{
              border: "1px solid #e4e4e4",
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
          >
            <Box style={{ width: "100%", display: "flex" }}>
              <Box style={{ ...styles.tableHeadRow, width: 90 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "networkStatus" ? "underline" : "none",
                  }}
                >
                  Online
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "networkStatus"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("networkStatus");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 200 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "name" ? "underline" : "none",
                  }}
                >
                  Nome
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "name"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("name");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 200 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "manufacturer" ? "underline" : "none",
                  }}
                >
                  Fabricante
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "manufacturer"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("manufacturer");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "description" ? "underline" : "none",
                  }}
                >
                  Descrição
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "description"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("description");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 200 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "createdAt" ? "underline" : "none",
                  }}
                >
                  Data de Cadastro
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "createdAt"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("createdAt");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 80 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "active" ? "underline" : "none",
                  }}
                >
                  Ativo
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "active"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("active");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
            </Box>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              {(data?.items).map((locker) => (
                <Link
                  key={`lockers_list_${locker._id}`}
                  to={`/lockers/${locker._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={styles.tableRow}>
                    <Box style={{ ...styles.row, width: 90 }}>
                      <Box
                        sx={{
                          width: 16.5,
                          height: 16.5,
                          padding: "0px 10px",
                          borderRadius: 30,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            backgroundColor:
                              locker?.networkStatus === "online"
                                ? Colors.lemonGreen
                                : Colors.red,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box style={{ ...styles.row, width: 200 }}>
                      <Text style={styles.cellText}>{locker?.name}</Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 200 }}>
                      <Text style={styles.cellText}>
                        {locker?.manufacturer}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, flex: 1 }}>
                      <Text style={styles.cellText}>
                        {locker?.description || "Não informada"}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 200 }}>
                      <Text style={styles.cellText}>
                        {formatTimestamp({
                          timestamp: locker?.createdAt,
                          showSeconds: false,
                        })}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 80 }}>
                      <Box
                        sx={{
                          ...styles.checkIcon,
                          zIndex: 99,
                          backgroundImage: locker?.active
                            ? `url('/icons/green_check_icon.png')`
                            : `url('/icons/gray_check_icon.png')`,
                          "&:hover": { opacity: 0.8 },
                        }}
                        onClick={(event) =>
                          handleLockerActivation(
                            event,
                            locker._id,
                            locker.active
                          )
                        }
                      />
                    </Box>
                    {!locker?.active && (
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#ffffffaa",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      />
                    )}
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        ) : (
          <NoDataFound />
        )}
      </ContentContainer>
    </>
  );
};

const styles = {
  root: {
    width: "100%",
    marginTop: 10,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  arrowUpIcon: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableHeadRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  tableHeadText: {
    fontSize: "14px",
    color: "#333",
    textAlign: "center",
    fontWeight: 600,
    marginLeft: 7,
  },
  tableRow: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 40,
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "#e4e4e4",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  cellText: {
    fontSize: 14,
    textAlign: "center",
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  openDoorButton: {
    backgroundColor: "#eee",
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    zIndex: 998,
    "&:hover": {
      backgroundColor: Colors.hover,
      cursor: "pointer",
    },
  },
  openDoorText: {
    fontSize: 14,
    textAlign: "center",
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0.5,
    paddingBottom: 0.5,
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: "content-box",
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
  paginationDropDownContainer: {
    position: "relative",
    width: 60,
    height: 30,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#ddd",
    display: "flex",
    alignItems: "center",
    paddingLeft: 2,
    paddingRight: 2,
    // backgroundColor: 'coral',
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  dropDownIcon: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 12,
    height: 12,
    marginLeft: 2,
  },
  paginationDropDownOptionsContainer: {
    position: "absolute",
    width: 94,
    left: 0,
    top: 32,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eee",
    zIndex: 9999999,
  },
  paginationDropDownOptions: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: 1,
    paddingBottom: 1,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  paginationButtonContainer: {
    position: "relative",
    height: 25,
    width: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  paginationButton: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    transform: "rotate(-90deg)",
  },
  confirmationDialogButton: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    height: 40,
    backgroundColor: Colors.lightBackground,
    borderRadius: 2,
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: Colors.hover,
    },
  },
  confirmationDialogArrow: {
    display: "flex",
    position: "absolute",
    right: -11,
    top: 60,
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: Colors.selectedBackground,
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: Colors.selectedBackground,
    transform: "rotate(45deg)",
  },
  confirmationDialogContainer: {
    zIndex: 10000,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: -310,
    width: 240,
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: Colors.selectedBackground,
    borderLeftWidth: 10,
    borderRadius: 2,
    padding: 2.5,
    alignItems: "center",
  },
};

// export const LockerList  ({ context }) => {
//    const history = useHistory();
//    const [lockers, setLockers] = useState([]);
//    const { setLoading } = context

//    useEffect(() => {
//       setLoading(true)

//    }, []);

//    const handleNewLocker = () => history.push(`/lockers/new`);

//    return (
//       <SectionContainer>
//          <SectionHeader
//             title={`Lockers (${lockers.length})`}
//             subtitle={`Ativos: ${lockers.filter(i => i.active).length}`}
//             newButtonAction={() => handleNewLocker()}
//             newButton={true}
//          />
//          <Spacer />
//          <TableContainer component={Paper}>
//             <Table>
//                <TableHead>
//                   <TableRow>
//                      <TableCell align="left" style={{ fontWeight: "bold" }}>
//                         Nome
//                      </TableCell>
//                      <TableCell align="center" style={{ fontWeight: "bold" }}>
//                         Ativo
//                      </TableCell>
//                   </TableRow>
//                </TableHead>
//                <TableBody>
//                   {lockers.map((locker, index) => (
//                      <LockerListRow locker={locker} key={`lockerrow${index}`} />
//                   ))}
//                </TableBody>
//             </Table>
//          </TableContainer>
//       </SectionContainer >
//    );
// };

// const LockerListRow = ({ locker }) => {
//    const history = useHistory();

//    return (
//       <TableRow
//          hover
//          sx={{ cursor: "pointer" }}
//          onClick={() => history.push(`/lockers/${locker?._id}`)}
//       >
//          <TableCell align="left" aria-label="Nome">
//             <Text sx={{ fontSize: 14 }}>{locker?.name}</Text>
//          </TableCell>
//          <TableCell align="center" aria-label="Ativo">
//             <Text sx={{ fontSize: 14 }}>{locker.active ? 'Ativo' : 'Inativo'}</Text>
//          </TableCell>
//       </TableRow >
//    );
// };
