import { Box } from "@mui/material";
import { useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Colors } from "../layout/Colors";

export const SearchBar = (props) => {
  const { placeholder = "", style = {}, onSearch, searchTerms } = props;

  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch(inputRef.current.value);
    } else if (event.key === "Escape") {
      handleClear();
    }
  };

  const handleClick = () => {
    onSearch(inputRef.current.value);
  };

  const handleClear = () => {
    inputRef.current.value = "";
    onSearch("");
  };

  const valueIsValid = Boolean(
    new URL(window.location).searchParams.get("searchTerms")
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={styles.searchBarContainer}>
        <input
          ref={inputRef}
          placeholder={placeholder}
          style={{ ...styles.searchBar, ...style }}
          defaultValue={searchTerms}
          onKeyDown={handleKeyDown}
        />

        {valueIsValid ? (
          <Box
            sx={{
              ...styles.button,
              background: "#e4e4e4",
              color: "#444",
              "&:hover": {
                background: "#e4e4e4e8",
              },
            }}
            onClick={handleClear}
          >
            <IoMdClose color="#444" />
            Limpar
          </Box>
        ) : (
          <></>
        )}
        <Box sx={styles.button} onClick={handleClick}>
          <FaSearch color="#fff" />
          Buscar
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  searchBarContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    borderRadius: 2,
    overflow: "hidden",
    backgroundColor: "lightblue",
  },
  searchBar: {
    padding: 18,
    paddingRight: 50,
    backgroundColor: Colors.background,
    border: "none",
    width: "100%",
    boxSizing: "border-box",
    outline: "none",
    fontSize: 14,
    color: "#999",
  },
  button: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    padding: "0px 24px",
    background: "#094047",
    color: "#fff",
    fontFamily: "Ubuntu",
    fontSize: 14,
    cursor: "pointer",
    "&:hover": {
      background: "#094047e8",
    },
  },
};
