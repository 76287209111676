import { Link } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import { useFetch } from "../../../hooks/useFetch";
import { usePagination } from "../../../hooks/usePagination";
import {
  Box,
  ContentContainer,
  Pagination,
  SearchBar,
  SectionHeader,
} from "../../W3libComponents";
import { FormControl, InputLabel, MenuItem, Select, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { StatusLabel } from "../../organisms/layout/StatusLabel";
import { NoDataFound } from "../../organisms/no-data-found/no-data-found";

export const ContractList = () => {
  const statusOptions = [
    {
      value: "active",
      display: "Ativo",
      color: Colors.green,
    },
    {
      value: "error",
      display: "Erro",
      color: Colors.red,
    },
    {
      value: "abandon-error",
      display: "Erro",
      color: Colors.red,
    },
    {
      value: "finished",
      display: "Finalizado",
      color: Colors.background,
    },
  ];
  const defaulterOptions = [
    {
      value: true,
      display: "Inadimplente",
      color: Colors.red,
    },
    {
      value: false,
      display: "Regular",
      color: Colors.green,
    },
  ];

  const {
    limit,
    page,
    skip,
    nextPage,
    prevPage,
    changeLimit,
    searchTerms,
    setSearchTerms,
    orderBy,
    setOrderBy,
    direction,
    setDirection,
    customFilter1: status,
    setCustomFilter1: setStatus,
  } = usePagination({
    localStorageName: "tuimbox-list-contracts-pagination",
    customFilter1: "all",
  });

  const { data, isLoading } = useFetch({
    url: `/v3/admin/contracts?page=${page}&skip=${skip}&limit=${limit}&orderBy=${orderBy}&direction=${direction}&searchTerms=${searchTerms}&status=${status}`,
  });

  const getContractRentalInterval = (contract) => {
    if (contract?.home_key) return "B2C";
    if (contract?.subscription) return "Assinatura";
    if (contract?.period?.name) return contract?.period?.name;

    if (contract?.interval === "hourly") return "Por Hora";
    if (contract?.interval === "daily") return "Diário";
    if (contract?.interval === "weekly") return "Semanal";
    if (contract?.interval === "monthly") return "Mensal";

    return "Avulso";
  };

  const formatUserName = (name) => {
    if (!!name) {
      const nameArray = name.trim().split(" ");
      return nameArray
        .map((word, index) =>
          index !== 0 && index !== nameArray.length - 1 && word.length > 2
            ? word.slice(0, 1) + "."
            : word
        )
        .join(" ");
    }

    return "Não informado";
  };

  if (isLoading) return <p>Carregando...</p>;

  return (
    <>
      <SectionHeader
        title={`Contratos`}
        subtitle={`Ativos: ${
          data?.items?.filter((i) => i.active)?.length || 0
        }`}
      />
      <ContentContainer>
        <Box sx={{ display: "flex", gap: 3 }}>
          <SearchBar
            placeholder="Nome do produto ou cliente"
            searchTerms={searchTerms}
            onSearch={setSearchTerms}
          />
          <FormControl
            style={{
              width: 240,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={status}
              label="Status"
              onChange={(e) => setStatus(e.target.value)}
              style={{ fontSize: 14, fontFamily: "Ubuntu" }}
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              <MenuItem value={"active"}>Ativos</MenuItem>
              <MenuItem value={"finished"}>Finalizados</MenuItem>
              <MenuItem value={"abandon-error"}>Erro</MenuItem>
            </Select>
          </FormControl>
          <Pagination
            localStorageName="tuimbox-list-contracts-pagination"
            dataLength={data?.contractCount?.[0]?.count || 0}
            nextPage={nextPage}
            prevPage={prevPage}
            page={page}
            limit={limit}
            changeLimit={changeLimit}
          />
        </Box>
        {data?.items?.length > 0 ? (
          <Box
            style={{
              border: "1px solid #e4e4e4",
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
          >
            <Box style={{ width: "100%", display: "flex" }}>
              <Box
                style={{
                  ...styles.tableHeadRow,
                  width: 150,
                  paddingLeft: 10,
                }}
              >
                <Text style={styles.tableHeadText}>Status</Text>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 180 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "createdAt" ? "underline" : "none",
                  }}
                >
                  Início
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "createdAt"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("createdAt");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                />
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "returnDate" ? "underline" : "none",
                  }}
                >
                  Término
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "returnDate"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("returnDate");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "products.name" ? "underline" : "none",
                  }}
                >
                  Produto
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "products.name"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("products.name");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 65 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "id_door.codLoopBox" ? "underline" : "none",
                  }}
                >
                  Porta
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "id_door.codLoopBox"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("id_door.codLoopBox");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "id_user" ? "underline" : "none",
                  }}
                >
                  Cliente
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "id_user"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("id_user");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 100 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "defaulter" ? "underline" : "none",
                  }}
                >
                  Situação
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "defaulter"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("defaulter");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                />
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 130 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "interval" ? "underline" : "none",
                  }}
                >
                  Aluguel
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "interval"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("interval");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                />
              </Box>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column" }}>
              {(data?.items).map((contract) => (
                <Link
                  key={`list_contracts_${contract?._id}`}
                  to={`/contracts/${contract?._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={styles.tableRow}>
                    <Box style={{ ...styles.row, width: 150, paddingLeft: 10 }}>
                      <StatusLabel
                        background="light"
                        currentStatus={contract?.status}
                        dropDownOptions={statusOptions}
                      />
                    </Box>
                    <Box
                      style={{
                        ...styles.row,
                        flexDirection: "column",
                        width: 180,
                      }}
                    >
                      <Text style={styles.cellText}>
                        {formatTimestamp({
                          timestamp: contract?.createdAt,
                          showFullYear: false,
                        })}
                      </Text>
                      <Text style={styles.cellText}>
                        {formatTimestamp({
                          timestamp: contract?.returnDate,
                          showFullYear: false,
                        })}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, flex: 1, overflow: "hidden" }}>
                      <Text
                        style={{
                          ...styles.cellText,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {contract?.products?.name}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 65 }}>
                      <Text style={styles.cellText}>
                        {contract?.id_door?.codLoopBox}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, flex: 1, overflow: "hidden" }}>
                      <Text
                        style={{
                          ...styles.cellText,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {formatUserName(contract?.id_user?.name)}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 100 }}>
                      <Text style={styles.cellText}>
                        <StatusLabel
                          background="light"
                          currentStatus={contract?.defaulter}
                          dropDownOptions={defaulterOptions}
                        />
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 130 }}>
                      <Text style={styles.cellText}>
                        {getContractRentalInterval(contract)}
                      </Text>
                    </Box>
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        ) : (
          <NoDataFound />
        )}
      </ContentContainer>
    </>
  );
};

const styles = {
  arrowUpIcon: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableHeadRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  tableHeadText: {
    fontSize: "14px",
    color: "#333",
    textAlign: "center",
    fontWeight: 600,
    marginLeft: 7,
  },
  tableRow: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "#e4e4e4",
    "&:hover": {
      backgroundColor: Colors.background,
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  cellText: {
    fontSize: 14,
    textAlign: "center",
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: "content-box",
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
  paginationDropDownContainer: {
    position: "relative",
    width: 60,
    height: 30,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#ddd",
    display: "flex",
    alignItems: "center",
    paddingLeft: 2,
    paddingRight: 2,
    // backgroundColor: 'coral',
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  dropDownIcon: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 12,
    height: 12,
    marginLeft: 2,
  },
  paginationDropDownOptionsContainer: {
    position: "absolute",
    width: 94,
    left: 0,
    top: 32,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eee",
    zIndex: 9999999,
  },
  paginationDropDownOptions: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: 1,
    paddingBottom: 1,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  paginationButtonContainer: {
    position: "relative",
    height: 25,
    width: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  paginationButton: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    transform: "rotate(-90deg)",
  },
  contractStatus: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "red",
  },
};
