import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { updatedFieldsConstructor } from "../../../helpers/updatedFieldsConstructor";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import { useFetch } from "../../../hooks/useFetch";
import { usePagination } from "../../../hooks/usePagination";
import api from "../../../services/api";
import {
  ContentContainer,
  Pagination,
  SearchBar,
  SectionHeader,
} from "../../W3libComponents";
import { Box, Spacer, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { NoDataFound } from "../../organisms/no-data-found/no-data-found";

export const ListDoors = ({ context }) => {
  const history = useHistory();
  const { setLoading, alert, user } = context;
  const { email: adminUserEmail } = user;
  const [showConfirmationDialog, setShowConfirmationDialog] = useState({
    active: false,
    id_loopbox_porta: 0,
  });

  const {
    limit,
    page,
    skip,
    nextPage,
    prevPage,
    changeLimit,
    searchTerms,
    setSearchTerms,
    orderBy,
    setOrderBy,
    direction,
    setDirection,
  } = usePagination({ localStorageName: "tuimbox-list-doors-pagination" });

  const { data } = useFetch({
    url: `/v3/admin/listdoors?page=${page}&skip=${skip}&limit=${limit}&searchTerms=${searchTerms}&orderBy=${orderBy}&direction=${direction}`,
  });

  const updateDoor = async ({ doorId, data }) => {
    setLoading(true);

    const originalDoorData = data.items.find((door) => door._id === doorId);
    const updatedDoorFields = updatedFieldsConstructor(
      ["active"],
      originalDoorData,
      data
    );

    const body = { data, updatedFields: updatedDoorFields, adminUserEmail };

    api
      .patch(`/v3/admin/door/${doorId}`, body)
      .then(() => alert.success("Porta atualizada com sucesso!"))
      .catch((err) => {
        console.log(err);
        alert.error("Desculpe. Não foi possível atualizar a porta.");
      })
      .finally(() => {
        setLoading(false);
        window.replace.reload();
      });
  };

  const getDoorStatus = (status) => {
    if (status === "available") return "Disponível";
    if (status === "delivered") return "Alugado";
    if (status === "leased") return "Não retirado";
    if (status === "access_code_expired") return "Código expirado";
  };

  const handleDoorActivation = (event, doorId, active) => {
    event.preventDefault();
    updateDoor({
      doorId,
      data: {
        active: !active,
      },
    });
  };

  const handleOpenDoor = async (doorId) => {
    setLoading(true);
    setShowConfirmationDialog({ ...showConfirmationDialog, active: false });

    await api
      .post(`/v3/admin/door/${doorId}/open`)
      .then((res) => {
        if (!res?.data?.success) throw res.data.error;
        alert.success("Abertura relizada com sucesso!");
      })
      .catch(() => alert.error("Não foi possível fazer abertura desta porta."))
      .finally(() => setLoading(false));
  };

  const handleNewDoor = () => history.push("/doors/new");

  return (
    <>
      <SectionHeader
        title={`Portas`}
        subtitle={
          data?.items
            ? `Ativos: ${(data?.items?.filter((i) => i.active)).length}`
            : ""
        }
        newButtonAction={() => handleNewDoor()}
        newButton={true}
      />

      <ContentContainer>
        <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
          <SearchBar
            placeholder="Nome do produto, locker ou número da porta"
            searchTerms={searchTerms}
            onSearch={setSearchTerms}
          />
          <Pagination
            localStorageName="tuimbox-list-doors-pagination"
            dataLength={data?.doorsCount?.[0]?.count || 0}
            nextPage={nextPage}
            prevPage={prevPage}
            page={page}
            limit={limit}
            changeLimit={changeLimit}
          />
        </Box>
        {data?.items?.length > 0 ? (
          <Box
            style={{
              border: "1px solid #e4e4e4",
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
          >
            <Box style={{ width: "100%", display: "flex" }}>
              <Box style={{ ...styles.tableHeadRow, width: 90 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "id_loopbox_porta" ? "underline" : "none",
                  }}
                >
                  ID
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "id_loopbox_porta"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("id_loopbox_porta");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 160 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "id_locker" ? "underline" : "none",
                  }}
                >
                  Locker
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "id_locker.name"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("id_locker.name");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "id_product" ? "underline" : "none",
                  }}
                >
                  Produto
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "id_product.name"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("id_product.name");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 80 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "codLoopBox" ? "underline" : "none",
                  }}
                >
                  Código
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "codLoopBox"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("codLoopBox");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 125 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "productStatus" ? "underline" : "none",
                  }}
                >
                  Status
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "productStatus"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("productStatus");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 170 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "createdAt" ? "underline" : "none",
                  }}
                >
                  Data de Cadastro
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "createdAt"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("createdAt");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 60 }}>
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      orderBy === "active" ? "underline" : "none",
                  }}
                >
                  Ativo
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      orderBy === "active"
                        ? direction === "asc"
                          ? `url('/icons/gray_arrow_down.png')`
                          : `url('/icons/gray_arrow_up.png')`
                        : `url('/icons/gray_arrow_down.png')`,
                  }}
                  onClick={() => {
                    setOrderBy("active");
                    setDirection(direction === "asc" ? "desc" : "asc");
                  }}
                ></Box>
              </Box>
              <Box style={{ ...styles.tableHeadRow, width: 100 }}>
                <Text style={styles.tableHeadText}>Abrir Porta</Text>
              </Box>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column" }}>
              {(data?.items).map((door) => (
                <Link
                  key={`doors_list_${door._id}`}
                  to={`/doors/${door._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={styles.tableRow}>
                    <Box style={{ ...styles.row, width: 90 }}>
                      <Text style={styles.cellText}>
                        {door?.id_loopbox_porta}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 160 }}>
                      <Text style={styles.cellText}>
                        {door?.id_locker?.name || "Não informado"}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, flex: 1 }}>
                      <Text style={styles.cellText}>
                        {door?.id_product?.name || "Não informado"}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 80 }}>
                      <Text style={styles.cellText}>{door?.codLoopBox}</Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 125 }}>
                      <Text style={styles.cellText}>
                        {getDoorStatus(door?.productStatus)}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 170 }}>
                      <Text style={styles.cellText}>
                        {formatTimestamp({
                          timestamp: door?.createdAt,
                          showSeconds: false,
                        })}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 60 }}>
                      <Box
                        sx={{
                          ...styles.checkIcon,
                          zIndex: 99,
                          backgroundImage: door?.active
                            ? `url('/icons/green_check_icon.png')`
                            : `url('/icons/gray_check_icon.png')`,
                          "&:hover": { opacity: 0.8 },
                        }}
                        onClick={(event) =>
                          handleDoorActivation(event, door._id, door.active)
                        }
                      />
                    </Box>
                    <Box style={{ ...styles.row, width: 100 }}>
                      {door.id_locker?.boxCode ? (
                        <Text>{`-`}</Text>
                      ) : (
                        <Box
                          sx={{
                            ...styles.openDoorButton,
                            position: "relative",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            setShowConfirmationDialog({
                              ...showConfirmationDialog,
                              active: true,
                              id_loopbox_porta: door.id_loopbox_porta,
                            });
                          }}
                        >
                          <Text sx={styles.openDoorText}>Abrir</Text>
                          {showConfirmationDialog.active &&
                            showConfirmationDialog.id_loopbox_porta ===
                              door.id_loopbox_porta && (
                              <Box
                                sx={styles.confirmationDialogContainer}
                                onClick={(e) => e.preventDefault()}
                              >
                                <Box sx={styles.confirmationDialogArrow} />
                                <Text
                                  style={{
                                    ...styles.cellTextBold,
                                    textAlign: "center",
                                  }}
                                >{`Deseja realmente abrir a porta ${door.codLoopBox}?`}</Text>
                                <Spacer size="2" />
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box
                                    sx={styles.confirmationDialogButton}
                                    onClick={(event) => {
                                      handleOpenDoor(door._id);
                                      // handleOpenDoor(door.id_loopbox_porta)
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }}
                                  >
                                    <Text style={styles.cellText}>SIM</Text>
                                  </Box>
                                  <Spacer horizontal />
                                  <Box
                                    sx={styles.confirmationDialogButton}
                                    onClick={(event) => {
                                      setShowConfirmationDialog({
                                        ...showConfirmationDialog,
                                        active: false,
                                      });
                                      event.preventDefault();
                                      event.stopPropagation();
                                    }}
                                  >
                                    <Text style={styles.cellText}>NÃO</Text>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                        </Box>
                      )}
                    </Box>
                    {(!door?.id_product?.name ||
                      !door?.active ||
                      door?.productStatus === "access_code_expired") && (
                      <Box
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor:
                            door?.productStatus === "access_code_expired"
                              ? Colors.red + "44"
                              : "#ffffffaa",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      />
                    )}
                  </Box>
                </Link>
              ))}
            </Box>
          </Box>
        ) : (
          <NoDataFound />
        )}
      </ContentContainer>
    </>
  );
};

const styles = {
  root: {
    width: "100%",
    marginTop: 10,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  arrowUpIcon: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableHeadRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  tableHeadText: {
    fontSize: "14px",
    color: "#333",
    textAlign: "center",
    fontWeight: 600,
    marginLeft: 7,
  },
  tableRow: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "#e4e4e4",
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  cellText: {
    fontSize: 14,
    textAlign: "center",
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  openDoorButton: {
    backgroundColor: Colors.background,
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    zIndex: 998,
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: "pointer",
    },
  },
  openDoorText: {
    fontSize: 14,
    textAlign: "center",
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0.5,
    paddingBottom: 0.5,
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: "content-box",
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
  confirmationDialogButton: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    height: 40,
    backgroundColor: Colors.lightBackground,
    borderRadius: 2,
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: Colors.hover,
    },
  },
  confirmationDialogArrow: {
    display: "flex",
    position: "absolute",
    right: -11,
    top: 60,
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: Colors.selectedBackground,
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: Colors.selectedBackground,
    transform: "rotate(45deg)",
  },
  confirmationDialogContainer: {
    zIndex: 10000,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: -310,
    width: 240,
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: Colors.selectedBackground,
    borderLeftWidth: 10,
    borderRadius: 2,
    padding: 2.5,
    alignItems: "center",
  },
};
